import { Auth } from 'aws-amplify'
import { setCookie } from 'nookies'
import { Cookies, ICartCheckoutPayload, ICartPayload, IAddCartItemPayload, ICartSelection } from '@/client/types'
import { api, protectedApi } from '@/client/utils'
import {
  customFiles,
  designServiceFiles,
  graphicTicketFiles,
  myorderFiles,
  folderEntires
} from '@/infra/data/labelLibrary'
/**
 * Gets folder lists
 * @param cartId
 * @returns
 */
export const getFolderEntries = async (customer_id: number) => {
  const url = `/library/${customer_id}`
  const { data } = await protectedApi.get<any>(url)
  return data?.customer_project_folders || []
}

/**
 * Gets table data.
 * @param cartId
 * @returns
 */
export const getTabledata = async (tableType: string, page: number, customerFolder: string, customer_id: number) => {
  if (!customer_id) {
    return null
  }

  const url = (customerFolder = `/customer/${customer_id}/customerFile?filter=${tableType}&page=${page}`)

  const { data } = await protectedApi.get<any>(url)

  return data
}
/**
 * Gets table data.
 * @param cartId
 * @returns
 */
export const getCustomTabledata = async (
  tableType: string,
  page: number,
  customerFolder: string,
  customer_id: number
) => {
  if (!customer_id) {
    return null
  }
  const url = (customerFolder = `/customer/${customer_id}/customerProjectFolder/${customerFolder}?page=${page}`)

  const { data } = await protectedApi.get<any>(url)
  // Hardcoding values till API is ready.
  return data
}

// Create folder
export const createFolder = async (payload, customer_id) => {
  const url = `/customer/${customer_id}/customerProjectFolder`
  const { data } = await protectedApi.post<any>(url, payload)
  return data
}
// Delete folder
export const deleteFolder = async (customer_id, id) => {
  const url = `/customer/${customer_id}/customerProjectFolder/${id}`
  const { data } = await protectedApi.delete<any>(url)
  return data
}
// Delete file
export const deleteFile = async (customer_id, folderIdid, fileId, payload) => {
  const url = `/customer/${customer_id}/customerProjectFolder/${folderIdid}/customerFile/${fileId}`
  const { data } = await protectedApi.delete<any>(url)
  return data
}
// Rename folder
export const renameFolder = async (customer_id, id, payload) => {
  const url = `/customer/${customer_id}/customerProjectFolder/${id}`
  const { data } = await protectedApi.patch<any>(url, payload)
  return data
}
// Move folder
export const moveToFolder = async (customer_id, folderIdid, fileId, payload) => {
  const url = `/customer/${customer_id}/customerProjectFolder/${folderIdid}/customerFile/${fileId}`
  const { data } = await protectedApi.patch<any>(url, payload)
  return data
}
// Download
export const moveToDownload = async (customer_id, folderIdid, fileId, currentPage, payload) => {
  const url = `/customer/${customer_id}/customerProjectFolder/${folderIdid}/customerFile/${fileId}?preview=0&page=${currentPage}`
  const { data } = await protectedApi.get<any>(url)
  return data
}
// Download
export const getPreview = async (customer_id, folderIdid, fileId, currentPage, payload) => {
  const url = `/customer/${customer_id}/customerProjectFolder/${folderIdid}/customerFile/${fileId}?preview=1&page=${currentPage}`
  const { data } = await protectedApi.get<any>(url, {
    responseType: 'blob'
  })
  return data
}
