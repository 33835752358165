/**
 * Retrieves the content from cms.
 *
 * @param {object} content - CMS row data.
 * @param {number|string} id - The unique identifier for the table row.
 * @param {any} item - An object or value associated with the specific row.
 * @returns {string} cms entry for based on the ID.
 */

export const getTableContent = (content, id, item) => {
  const getData = content.filter((e) => e['id'] === id)
  return getData.length > 0 ? getData[0][item] : ''
}
