/**
 * Color Pallet semantic names
 *
 * Pallet tone is one of:
 * - pure
 * - dark
 * - light
 * - pale
 */

import { DynamicKeys } from '@/client/types'

type DynamicColorKeys = {
  [key: string]: DynamicKeys
}

const grayscale: DynamicKeys = {
  black: '#151617',
  darker: '#2C2C3A',
  dark: '#72747b',
  lightDark: '#3A3A3A',
  gray: '#DADDDF',
  light: '#EAEDF0',
  lighter: '#F4F6F9',
  grayLigher: '#EDF0F7',
  white: '#ffffff',
  blue: '#00263E',
  grayLight: '#f3f6f9',
  grayLightest: '#f0f6fc',
  silver: '#f7f7f7',
  darkSilver: '#E4E6E9',
  lightBlue: '#f3fcff',
  lightPurple: '#F1F1F9',
  lightMinimal: '#AEAEAF',
  greyMinimal: '#7D7F85',
  greyMax: '#8A9099',
  borderWhite: '#92949B',
  minwhite: '#BEBEBE',
  lightwhite: '#F5F7FA',
  lightblue: '#4798C2',
  headerGray: '#e9e9e9',
  grayLighter: '#F6F8FA',
  lightBlueShade: '#0292C2',
  greyalpha: '#7A7B83',
  lightAlpha: '#DDDDDD',
  lightOrange: '#FF4E08',
  lavenderBlue: '#6B7AD9',
  ghostWhite: '#F1F1F9',
  goldenBrown: '#CC994E',
  darkNavyBlue: '#122239',
  lightWhite: '#F8FAFF',
  lavender: '#EFE5FF',
  turquoiseBlue: '#1AACD3'
}

const primary: DynamicKeys = {
  paleAlpha: '#A5A5A5',
  pale: '#e5f5ff',
  lighter: '#66C3FF',
  light: '#7BCAFF',
  veryLight: '#E3F3FF',
  lightPale: 'rgba(120, 120, 120, 0.20)',
  pure: '#008FBE',
  dark: '#005F7F',
  darker: '#00263E',
  black: '#000000',
  blueCyan: '#4BDFFC',
  turquoiseBlue: '#1AACD3'
}

const dark: DynamicKeys = {
  pure: grayscale.darker,
  dark: grayscale.black,
  light: grayscale.dark
}

const success: DynamicKeys = {
  pale: '#e5f3ef',
  lighter: '#80FFDA',
  light: '#08FFB8',
  pure: '#07EDAB',
  dark: '#00C972',
  darker: '#048560'
}

const info: DynamicKeys = {
  pale: '#f2e5ff',
  lighter: '#B366FF',
  light: '#9933FF',
  pure: '#8000FF',
  dark: '#6700CC',
  darker: '#4D0099'
}

const variant: DynamicKeys = {
  pale: '#fff1e5',
  lighter: '#FFAE66',
  light: '#FF9333',
  pure: '#FF7800',
  dark: '#CC6000',
  darker: '#994800'
}

const warning: DynamicKeys = {
  pale: '#fff9e5',
  lighter: '#FFDE66',
  light: '#FFD333',
  pure: '#FFB703',
  dark: '#CCA000',
  darker: '#997800',
  pureDarker: '#FFB706'
}

const danger: DynamicKeys = {
  pale: '#ffe5ed',
  lighter: '#FF6695',
  light: '#FF3371',
  pure: '#FF004E',
  dark: '#CC0040',
  darker: '#99002F'
}

const neutral: DynamicKeys = {
  pure: grayscale.light,
  dark: grayscale.gray,
  light: grayscale.white
}

const inverse: DynamicKeys = {
  pure: grayscale.white,
  dark: grayscale.light
}

const text: DynamicKeys = {
  normal: primary.darker,
  weak: grayscale.dark,
  inverse: grayscale.white,
  primary: primary.pure,
  'primary-light': primary.light,
  'primary-dark': primary.dark,
  success: success.dark,
  warning: warning.dark,
  danger: danger.dark,
  black: primary.black,
  paleAlpha: primary.paleAlpha,
  lightBlueShade: grayscale.lightBlueShade
}

const color: DynamicColorKeys = {
  primary,
  success,
  info,
  variant,
  warning,
  danger,
  neutral,
  dark,
  inverse,
  grayscale,
  text
}

export default Object.freeze(color)
