import { FC, memo, useEffect, useState, useCallback, useContext } from 'react'
import { IconSizes } from '@/client/components/Icon/types'
import {
  Row,
  Col,
  Breadcrumb,
  Checkbox,
  Button,
  Link,
  Modal,
  Input,
  Select2,
  Loader,
  ProgressLoader
} from '@/client/components'
import * as S from './styles'
import { Icon } from '@/client/components/Icon'
import { Text } from '@/client/components/Text'
import { MultiTableProps } from './types'
import { useGetFolderEntries, useGetCustomTableData, useGetUserInfos, useRouterPush } from '@/client/hooks'
import { formatDate } from '@/client/utils/formatDate'
import { CloseModal } from '../../CookieConsent/styles'
import { useMultiChunkUpload } from '@/client/hooks/useMultiChunkUpload'
import { uploadChunkFiles } from '@/infra/services/uploadFile'
import { useMutation } from '@tanstack/react-query'
import { LabelLibraryContext } from '@/client/context/labelLibrary'
import router, { useRouter } from 'next/router'
import { boolean } from 'zod'
const isObjectEmpty = (obj: { [key: string]: string }) => Object.keys(obj).length === 0 && obj.constructor === Object

export const Controls: FC<any> = memo(({ currentPage, goPrevious, goNext, totalPage, perPage }) => {
  return (
    <Row end='sm'>
      {currentPage > 1 && (
        <S.ActionButtons>
          <Button kind='dark' onClick={goPrevious}>
            Previous Page
          </Button>
        </S.ActionButtons>
      )}
      {perPage !== 0 && currentPage * perPage <= totalPage && (
        <S.ActionButtons>
          <Button kind='dark' onClick={goNext}>
            Next Page
          </Button>
        </S.ActionButtons>
      )}
    </Row>
  )
})

Controls.displayName = 'Controls'
