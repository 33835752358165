import { ChangeEvent, FC, Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Icon, Input, SelectCard, Text, Tooltip, SelectCardCheckBox, Checkbox, Image } from '@/client/components'
import { CartContext } from '@/client/context/cart'
import { useGetMaterial, useMutatePrices } from '@/client/hooks'
import { IFieldOption } from '@/client/types'
import * as S from '../styles'
import { IconName, IconSizes } from '@/client/components/Icon/types'
import { FieldType, SettingsChildProps, getCalculatorConstants } from '../types'
import { List } from '@/client/components'
import { ListItem } from '@/client/components'

export const CalculatorStep: FC<SettingsChildProps> = ({
  fields,
  step,
  randomText,
  initialSelection,
  reset,
  setResetFalse,
  serviceType,
  helperText
}) => {
  const { locale } = useRouter()
  const { mutate } = useMutatePrices(locale)
  const { updateSelection, selection } = useContext(CartContext)
  const [keyType, setKeyType] = useState(false)
  const [adhesiveValue, setAdhesiveValue] = useState(initialSelection['adhesive'])
  const [showDropdown, setShowDropdown] = useState({
    state: false,
    id: null,
    top: '115px',
    index: 0,
    closeCurrent: false,
    relatedFieldId: null,
    optionId: null
  })
  const [verticalRadioOptionMargin, setVerticalRadioOptionMargin] = useState(false)
  const [selectedMaterialId, setSelectedMaterialId] = useState(null)
  const { data: materialData, refetch } = useGetMaterial(selectedMaterialId, locale)
  const vRadioRef = useRef<Array<HTMLDivElement | null>>([])
  const relativeParentRef = useRef<HTMLDivElement | null>(null)
  const optionWrapperSet = useRef<HTMLDivElement | null>(null)

  const [maxWidth, setMaxWidth] = useState<any>(585)
  const [maxHeight, setMaxHeight] = useState<any>(308)

  const maxWidthRef = useRef(maxWidth)
  const maxHeightRef = useRef(maxHeight)

  const [multiSelectForFinishing, setMultiSelectForFinishing] = useState<boolean>(false)
  const [foilHeading, setFoilHeading] = useState<string | undefined>()

  useEffect(() => {
    const maxValue = (fieldName, optionName) => {
      const field: any = fields?.find((field) => field.key === fieldName)
      return field?.options.find((option) => option.key === optionName).max ?? 0
    }
    setMaxHeight(maxValue('dimension', 'height'))
    setMaxWidth(maxValue('dimension', 'width'))
  }, [fields])

  useEffect(() => {
    maxWidthRef.current = maxWidth
    maxHeightRef.current = maxHeight
  }, [maxWidth, maxHeight])

  const handleChange = useCallback(
    (optionId, value) => {
      const newSelection = {
        ...selection,
        [optionId]: value
      }
      if (optionId === 'quantity' && serviceType && ['pro', 'smart', 'premium', 'basic'].includes(serviceType)) {
        newSelection['quantity'] = 10
      }
      if (optionId === 'sorts' && serviceType === 'premium') {
        newSelection['sorts'] = 1
      }

      updateSelection(newSelection)
    },
    [selection, updateSelection]
  )

  const handleBlur = useCallback(
    (optionId, value) => {
      mutate({
        ...selection,
        [optionId]: value
      })
    },
    [selection, mutate]
  )

  const handleSelect = useCallback(
    (optionId, value, relatedFieldId, relatedField, key) => {
      if (key === 'relief_embossing') {
        setFoilHeading(getCorrectFoilName(key))
      } else if (key === 'foil') {
        setFoilHeading(undefined)
      }

      const newSelection = {
        ...selection,
        [optionId]: value,
        [relatedField]: relatedFieldId,
        ...(key === 'no_finishing' || key === 'blind_embossing'
          ? {
              foil: initialSelection['foil'],
              embossing_tool_material: initialSelection['embossing_tool_material']
            }
          : {}),
        ...(key === 'relief_embossing' ? { embossing_tool_material: initialSelection['embossing_tool_material'] } : {})
      }

      setMultiSelectForFinishing(shouldEnableMultiSelect(key, newSelection))
      updateSelection(newSelection)
      mutate(newSelection)
      if (key === 'sheet') {
        setTimeout(() => {
          handleA4Dimensions(newSelection)
        }, 1000)
      }
    },
    [mutate, selection, updateSelection, maxWidth, maxHeight]
  )

  const handleA4Dimensions = (newSelection) => {
    newSelection['width'] = Math.min(newSelection['width'], maxWidthRef.current)
    newSelection['height'] = Math.min(newSelection['height'], maxHeightRef.current)

    updateSelection(newSelection)
    mutate(newSelection)
  }

  const handleSelectRadioVertical = useCallback(
    (optionId, value, relatedFieldId, relatedField, index) => {
      // Set dropdown closed first.
      setShowDropdown((prevState) => ({
        state: false,
        id: value,
        top: prevState.top,
        index: index,
        closeCurrent: true,
        relatedFieldId: relatedFieldId,
        optionId: optionId
      }))
      const newSelection = {
        ...selection,
        [optionId]: value,
        [relatedField]: relatedFieldId
      }
      updateSelection(newSelection)
      mutate(newSelection)
    },
    [mutate, selection, updateSelection]
  )

  const handleSelectCheckbox = useCallback(
    (optionId, key, nextFieldId, relatedField, relatedFieldId) => {
      const newSelection = {
        ...selection,
        [optionId]: nextFieldId,
        [relatedField]: selection[relatedField]?.filter((item) => item !== relatedFieldId)
      }

      key === 'false' ? setKeyType(true) : setKeyType(false)

      updateSelection(newSelection)
      mutate(newSelection)
    },
    [mutate, selection, updateSelection]
  )

  const handleCheckbox = useCallback(
    (optionId, value) => {
      const newSelection = {
        ...selection,
        [optionId]: selection[optionId]?.includes(value)
          ? selection[optionId]?.filter((item) => item !== value) || []
          : [...(selection[optionId] || []), value]
      }

      updateSelection(newSelection)
      mutate(newSelection)
    },
    [mutate, selection, updateSelection]
  )

  const shouldEnableMultiSelect = (key: string, newSelection) =>
    newSelection['finishing'] === 117 && newSelection['foil'] !== initialSelection['foil']

  const handleMultipleSelect = useCallback(
    (checked: boolean, key) => {
      if (key === 'foil' || key === 'digital_foil') {
        return multiSelectForFinishing ? multiSelectForFinishing : checked
      } else {
        return checked
      }
    },
    [multiSelectForFinishing]
  )

  const showField = useCallback(
    (excludes): boolean => {
      if (!excludes) return true
      const isExcluded = excludes.some(({ id, value }) => selection[id] === value)
      return !isExcluded
    },
    [selection]
  )

  const isDisabled = useCallback(
    (disableList): boolean => {
      if (!disableList) return false
      const isDisabled = disableList.some(({ key, value }) => selection[key] === value)
      return isDisabled
    },
    [selection]
  )

  const checkCorrectSize = useCallback((value: string, max?: string, min?: string) => {
    const maxSize = Number(max)
    const minSize = Number(min)
    const currentValue = Number(value)

    if (currentValue > maxSize) return maxSize
    if (currentValue < minSize) return minSize
    return currentValue
  }, [])

  const handleInputDisabled = useCallback(
    (filedKey: string, optionKey: string) => {
      if (filedKey !== 'assembly') return false
      if (optionKey === 'labels_per_roll' && selection?.max_roll_diameter > 0) {
        return true
      }

      if (optionKey === 'max_roll_diameter' && selection?.labels_per_roll > 0) {
        return true
      }

      return false
    },
    [selection]
  )

  const activeDropDown = useCallback(
    (id, index) => {
      setSelectedMaterialId(id)
      setVerticalRadioOptionMargin(true)
      const clickedElement = vRadioRef.current[index]
      const childLabelElem = clickedElement?.querySelector('label')
      const parentWrapper = relativeParentRef.current

      const optionWrapper = optionWrapperSet.current
      if (clickedElement && parentWrapper && childLabelElem) {
        const { top } = clickedElement?.getBoundingClientRect()
        const { top: parentWrapperTop } = parentWrapper.getBoundingClientRect()
        const wrapperHeight = optionWrapper?.getBoundingClientRect()?.height || 0
        setShowDropdown((prevState) => ({
          state: true,
          id: id,
          top: `${top - parentWrapperTop + childLabelElem.clientHeight + 10}px`,
          index: index,
          closeCurrent: prevState.closeCurrent,
          relatedFieldId: prevState.relatedFieldId,
          optionId: prevState.optionId
        }))
      }
    },
    [showDropdown]
  )

  const adhesiveName = useCallback(
    (glueId: number | undefined): any => {
      return fields.find(({ key }) => key === 'adhesive')?.options.find(({ key }) => key === glueId?.toString())?.name
    },
    [fields]
  )

  const materialName = useCallback(
    (attributeId: number | undefined): any => {
      return fields.find(({ key }) => key === 'paper')?.options.find(({ id }) => +id === attributeId)?.name
    },
    [fields]
  )

  const truncateString = (str, maxLength) => {
    return str.length > maxLength ? str.slice(0, maxLength) + '...' : str
  }

  const getCorrectFoilName = useCallback(
    (keyString) => {
      return fields.find(({ key }) => key === 'finishing')?.options.find(({ key }) => key === keyString?.toString())
        ?.name
    },
    [fields]
  )

  useEffect(() => {
    fields.forEach((field, index) => {
      if (!field.force_value) return
      const { id, key, value, nextFieldId, relatedFieldId, relatedField } = field.force_value
      const onChangeTypes: Partial<Record<FieldType, () => void>> = {
        ['textarea']: () => handleChange(key, value),
        ['input-number']: () => handleChange(key, value),
        ['range']: () => handleChange(id, value),
        ['select-radio']: () => handleSelect(field.key, value, relatedFieldId, relatedField, key),
        ['select-radio-vertical']: () => handleSelectRadioVertical(field.key, id, relatedFieldId, relatedField, index),
        ['select-card-checkbox']: () => handleSelectCheckbox(field.key, key, nextFieldId, relatedFieldId, relatedField),
        ['checkbox']: () => handleCheckbox(field.key, id)
      }
      onChangeTypes[field.type]?.()
    })

    /**
     *  ----------------------------------------------------------
     * |                                                          |
     * | NOTE: Temporary Frontend Logic                           |
     * |                                                          |
     * | The following code implements temporary frontend logic.  |
     * | It is placeholder functionality and will be replaced     |
     * | with a permanent solution once provided by the backend.  |
     * |                                                          |
     * | Remember to remove this temporary code and               |
     * | its dependencies when integrating                        |
     * | the permanent solution.                                  |
     * |                                                          |
     */
    const paperElement = fields.find((e) => e['key'] === 'paper')
    const filteredOption = paperElement?.['options']?.find((e) => e['id'] === showDropdown.id)
    const currentOptionId = showDropdown.optionId ? showDropdown.optionId : 'paper'

    /*
     * |                                                           |
     * | Selecting first paper in the first load                   |
     * |                                                           |
     */

    if (!showDropdown.id || reset) {
      setResetFalse && setResetFalse()
      setVerticalRadioOptionMargin(false)
      const getFirst = paperElement?.['options'][0]
      handleSelectRadioVertical(currentOptionId, getFirst?.['id'], null, null, 0)
    }

    /*
     * |                                                           |
     * | Selecting first paper when adhesive changes               |
     * | and currently selected paper ID doesn't exist             |
     * |                                                           |
     */

    if (!filteredOption) {
      setVerticalRadioOptionMargin(false)
      const getFirst = paperElement?.['options'][0]
      handleSelectRadioVertical(currentOptionId, getFirst?.['id'], null, null, 0)
    }

    /**
     * | End of Temporary Frontend Logic                         |
     * |                                                         |
     *  ---------------------------------------------------------
     */

    // Dropdown toggles.
    if (!showDropdown.state) {
      setVerticalRadioOptionMargin(false)
      activeDropDown(showDropdown.id, showDropdown.index)
    }
  }, [fields, showDropdown.state, selectedMaterialId, step, reset])

  return (
    <>
      {getCalculatorConstants(serviceType)
        .filter((el) => el.step === step)[0]
        ?.fields.map((subfield, index) => (
          <Fragment key={index}>
            {fields
              .filter((er) => er.key == subfield.key)
              .map((field) => (
                <Fragment key={field?.key}>
                  <>
                    {showField(field?.exclude) && (
                      <S.Block className={`tour-${field.key}`}>
                        <Text
                          size='lg'
                          family='heading'
                          weight='bold'
                          margin='0 0 0.8rem'
                          color={selection[field?.key] ? 'normal' : 'danger'}
                        >
                          {field.key === 'foil' ? foilHeading ?? field.displayName : field.displayName}
                          {field.hint && (
                            <S.Hint>
                              <Tooltip title={field.hint}>
                                <Icon name='info' />
                              </Tooltip>
                            </S.Hint>
                          )}

                          {!selection[field?.key] && <Icon name='warning' size='s4' />}
                        </Text>

                        <S.Option>
                          {field.type === 'input-number' && (
                            <>
                              {field?.options?.map(
                                ({ id, name, key, unit, min, max, description, lock }: IFieldOption) => (
                                  <S.CBlock>
                                    <Input
                                      dimension='large'
                                      type='number'
                                      min={min}
                                      max={max}
                                      key={id}
                                      id={id}
                                      name={name}
                                      description={description}
                                      disabled={lock == 1 || handleInputDisabled(field.key, key)}
                                      label={`${name} ${unit ?? ''}`}
                                      value={selection[key]}
                                      onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                                        handleChange(key, Number(event.currentTarget.value).toFixed())
                                      }}
                                      onBlur={(event: ChangeEvent<HTMLInputElement>): void => {
                                        const { value } = event.currentTarget

                                        const valueFormatted = checkCorrectSize(value, max, min)
                                        handleBlur(key, Number(valueFormatted).toFixed())
                                        handleChange(key, Number(valueFormatted).toFixed())
                                      }}
                                      style={{ width: field.key === 'assembly' ? '16rem' : 'auto' }}
                                    />
                                    {/* Helper text comes from cms, for bottom  */}
                                    {/* {helperText
                                      ?.filter((e) => e['id'] == id)
                                      .map((ee) => (
                                        <S.CBlockInfo>
                                          <S.Hint>
                                            <Icon name='info' color='#808891' />
                                          </S.Hint>
                                          <div
                                            className='cblock-info-para-div'
                                            dangerouslySetInnerHTML={{ __html: ee['description'] }}
                                          ></div>
                                        </S.CBlockInfo>
                                      ))} */}
                                  </S.CBlock>
                                )
                              )}
                            </>
                          )}

                          {field.type === 'select-radio' && (
                            <>
                              {field?.options?.map(
                                ({
                                  id,
                                  name,
                                  icon,
                                  description,
                                  disabled,
                                  lock,
                                  key,
                                  warning,
                                  relatedFieldId,
                                  relatedField
                                }: IFieldOption) => (
                                  <SelectCard
                                    key={id}
                                    id={id}
                                    value={id}
                                    name={field.key}
                                    label={name}
                                    description={description}
                                    warning={warning}
                                    disabled={lock == 1 || isDisabled(disabled)}
                                    checked={id === selection[field.key]}
                                    onChange={() => handleSelect(field.key, id, relatedFieldId, relatedField, key)}
                                    iconName={icon}
                                  />
                                )
                              )}
                            </>
                          )}

                          {field.type === 'select-radio-vertical' && field.key === 'paper' && (
                            <S.VerticalRadioOption dropdownState={verticalRadioOptionMargin}>
                              <S.Option className='v-option-wrapper' ref={relativeParentRef}>
                                {field?.options?.map(
                                  (
                                    {
                                      id,
                                      name,
                                      description,
                                      icon,
                                      disabled,
                                      lock,
                                      warning,
                                      relatedFieldId,
                                      relatedField
                                    }: IFieldOption,
                                    index
                                  ) => (
                                    <div
                                      className={
                                        showDropdown.state && +id === showDropdown.id
                                          ? 'v-select-card dropdown-active'
                                          : 'v-select-card'
                                      }
                                      ref={(ref) => (vRadioRef.current[index] = ref)}
                                    >
                                      <div className='left-fq'></div>
                                      <SelectCard
                                        key={id}
                                        orientation='vertical'
                                        id={id}
                                        value={id}
                                        name={field.key}
                                        label={truncateString(name, 58)}
                                        description={description}
                                        warning={warning}
                                        disabled={lock == 1 || isDisabled(disabled)}
                                        checked={id === selection[field.key]}
                                        onChange={() =>
                                          handleSelectRadioVertical(field.key, id, relatedFieldId, relatedField, index)
                                        }
                                        iconName={icon}
                                      >
                                        <S.DropLink
                                          className={
                                            showDropdown.state && +id === showDropdown.id
                                              ? 'icon-minus-xl'
                                              : 'icon-plux-xl'
                                          }
                                        ></S.DropLink>
                                      </SelectCard>
                                      <div className='right-fq'></div>
                                    </div>
                                  )
                                )}
                                {showDropdown.state && (
                                  <S.OptionDropdown style={{ top: showDropdown.top }} ref={optionWrapperSet}>
                                    {/* Material API */}
                                    <>
                                      <S.MaterialImage>
                                        <Image src={materialData?.image_url} alt='paper' width={165} height={220} />
                                      </S.MaterialImage>
                                      <S.MaterialDetails>
                                        <>
                                          <Text>{materialName(materialData?.attribute_id)}</Text>
                                          <List kind='gold'>
                                            <ListItem
                                              key='key1'
                                              iconName={'liquid-resistance' as IconName}
                                              iconSize={'s3' as IconSizes}
                                              weight={'normal'}
                                              border={'bottom'}
                                            >
                                              {Array.from(
                                                { length: materialData?.liquid_resistance as number },
                                                (_) => (
                                                  <S.IconAligner>
                                                    <Icon name='circle-filled' size='s2' />
                                                  </S.IconAligner>
                                                )
                                              )}
                                              {Array.from(
                                                { length: 3 - (materialData?.liquid_resistance as number) },
                                                (_) => (
                                                  <S.IconAligner>
                                                    <Icon name='circle-unfilled' size='s2' />
                                                  </S.IconAligner>
                                                )
                                              )}
                                            </ListItem>
                                            <ListItem
                                              key='key2'
                                              iconName={'new-durability' as IconName}
                                              iconSize={'s3' as IconSizes}
                                              weight={'normal'}
                                              border={'bottom'}
                                            >
                                              <Text margin='0'>{materialData?.min_temperature} °C </Text>
                                              <Image
                                                src={`/icons/temperature.svg`}
                                                alt='temperature'
                                                width={50}
                                                height={20}
                                              />
                                              <Text margin='0'> {materialData?.max_temperature} °C </Text>
                                            </ListItem>
                                            <ListItem
                                              key='key3'
                                              iconName={'new-permanent' as IconName}
                                              iconSize={'s3' as IconSizes}
                                              weight={'normal'}
                                              border={'bottom'}
                                            >
                                              <Text margin='0'>
                                                {!adhesiveName(materialData?.glue_id)
                                                  ? ' - '
                                                  : adhesiveName(materialData?.glue_id)}
                                              </Text>
                                            </ListItem>
                                            <ListItem
                                              key='key4'
                                              iconName={'recycle' as IconName}
                                              iconSize={'s3' as IconSizes}
                                              weight={'normal'}
                                              border={'bottom'}
                                            >
                                              <S.IconAligner>
                                                <Icon name='eco' />
                                              </S.IconAligner>
                                              <S.IconAligner>
                                                <Icon name='fsc' />
                                              </S.IconAligner>

                                              <Text margin='0'>
                                                {!materialData?.certificate ? ' - ' : materialData?.certificate}{' '}
                                              </Text>
                                            </ListItem>
                                            <S.IconDetails>
                                              <Icon name='liquid-resistance' size='s2' />{' '}
                                              {randomText?.liquid_resistance}
                                              {'   '}
                                              <Icon name='new-durability' size='s2' />
                                              {'   '}
                                              {randomText?.durability}
                                              {'   '} <Icon name='new-permanent' size='s2' />
                                              {randomText?.adhesive}
                                            </S.IconDetails>
                                          </List>
                                        </>
                                      </S.MaterialDetails>
                                      <S.MaterialDescription>
                                        <Text margin='0.59rem 0'>{randomText?.recommendation}</Text>
                                        <Text>
                                          {!materialData?.recommendation ? '-' : materialData?.recommendation}
                                        </Text>
                                      </S.MaterialDescription>
                                    </>
                                  </S.OptionDropdown>
                                )}
                              </S.Option>
                            </S.VerticalRadioOption>
                          )}

                          {field.type === 'select-radio-vertical' && field.key === 'finishing' && (
                            <S.Option className='finishing-option' ref={relativeParentRef}>
                              {field?.options?.map(
                                ({
                                  id,
                                  name,
                                  description,
                                  icon,
                                  disabled,
                                  lock,
                                  warning,
                                  key,
                                  relatedFieldId,
                                  relatedField
                                }: IFieldOption) => (
                                  <SelectCard
                                    key={id}
                                    orientation='vertical'
                                    id={id}
                                    value={id}
                                    name={field.key}
                                    label={name}
                                    warning={warning}
                                    disabled={lock == 1 || isDisabled(disabled)}
                                    checked={handleMultipleSelect(id === selection[field.key], key)}
                                    onChange={() => handleSelect(field.key, id, relatedFieldId, relatedField, key)}
                                    giveBackground={`/img/${icon}.png`}
                                    iconName={icon}
                                    textClassName='finishing-text'
                                    checkbox={true}
                                  />
                                )
                              )}
                            </S.Option>
                          )}

                          {field.type === 'select-radio-vertical' &&
                            field.key !== 'paper' &&
                            field.key !== 'finishing' && (
                              <S.Option className='v-option-wrapper' ref={relativeParentRef}>
                                {field?.options?.map(
                                  ({
                                    id,
                                    name,
                                    description,
                                    icon,
                                    disabled,
                                    lock,
                                    warning,
                                    key,
                                    relatedFieldId,
                                    relatedField
                                  }: IFieldOption) => (
                                    <SelectCard
                                      key={id}
                                      orientation='vertical'
                                      id={id}
                                      value={id}
                                      name={field.key}
                                      label={name}
                                      description={description}
                                      warning={warning}
                                      disabled={lock == 1 || isDisabled(disabled)}
                                      checked={id === selection[field.key]}
                                      onChange={() => handleSelect(field.key, id, relatedFieldId, relatedField, key)}
                                      iconName={icon}
                                    />
                                  )
                                )}
                              </S.Option>
                            )}

                          {field.type === 'select-card-checkbox' && (
                            <>
                              {field?.options?.map(
                                ({
                                  id,
                                  name,
                                  description,
                                  key,
                                  nextFieldId,
                                  relatedFieldId,
                                  relatedField,
                                  icon
                                }: IFieldOption) => (
                                  <SelectCardCheckBox
                                    key={id}
                                    id={id}
                                    value={id}
                                    name={field.key}
                                    label={name}
                                    description={description}
                                    checked={key === 'false' ? false : true}
                                    kind='circle'
                                    subText=''
                                    rightEndText=''
                                    boxType='withIcon'
                                    iconName={icon}
                                    onChange={() =>
                                      handleSelectCheckbox(field.key, key, nextFieldId, relatedField, relatedFieldId)
                                    }
                                  />
                                )
                              )}
                            </>
                          )}

                          {field.type === 'checkbox' && (
                            <>
                              {field?.options?.map(({ id, name, description }: IFieldOption) => (
                                <Checkbox
                                  key={id}
                                  id={id}
                                  value={id}
                                  name={field.key}
                                  label={name}
                                  description={description}
                                  checked={selection[field.key].includes(id)}
                                  kind='square'
                                  onChange={() => handleCheckbox(field.key, id)}
                                />
                              ))}
                            </>
                          )}
                        </S.Option>

                        {field.error_message && (
                          <Text margin='0.8rem 0 0' color='danger' size='sm'>
                            {field.error_message}
                          </Text>
                        )}
                      </S.Block>
                    )}
                  </>
                </Fragment>
              ))}
          </Fragment>
        ))}
    </>
  )
}
