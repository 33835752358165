import styled, { css } from 'styled-components'
import { Button, HorizontalScroll, Icon, theme } from '@/client/components'
import { StepStylesProps } from './types'
const { borders, breakpoint, color, font, space } = theme

export const SettingsInner = styled.div<{ open: boolean }>`
  position: relative;
  overflow: hidden;
  transition: max-height ${(p) => (p.open ? '1.5s ease-out' : '0.4s ease-in')};
  max-height: ${(p) => (p.open ? 'auto' : '578px')};
`

export const TopCalculator = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${space.xl5};
  padding: 0 ${space.md};
  border-radius: ${borders.radius.lg} ${borders.radius.lg} 0 0;
  background-color: ${color.primary.darker};

  @media (min-width: ${breakpoint.xl}) {
    height: ${space.xl4};
  }
`
export const TopCalculatorPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-width: 6rem;

  @media (min-width: ${breakpoint.xl}) {
    display: none;
  }
`

export const StepsWrapper = styled.div`
  position: relative;
  width: calc(100% - 6rem);
`

export const TopCalculatorGradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${space.xl2};
  height: 100%;
  background: rgb(0, 38, 62);
  background: linear-gradient(90deg, rgba(0, 38, 62, 0) 0%, rgba(0, 38, 62, 0.55) 60%, rgba(0, 38, 62, 1) 100%);

  @media (min-width: ${breakpoint.xl}) {
    display: none;
  }
`

export const Steps = styled.nav`
  position: relative;
  display: flex;
  overflow-x: auto;
  align-items: center;
  justify-content: flex-start;
  padding-right: ${space.xl4};

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${breakpoint.xl}) {
    padding: 0 ${space.lg};
  }

  @media (min-width: ${breakpoint.hd}) {
    padding: 0 ${space.xl2};
  }
`

export const NextIcon = styled(Icon)`
  display: none;
  color: ${color.grayscale.white};

  @media (min-width: ${breakpoint.xl}) {
    display: block;
    position: relative;
    left: -${space.md};
  }

  @media (min-width: ${breakpoint.hd}) {
    left: -${space.xl};
  }
`

export const Step = styled.span<StepStylesProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${space.xl4};
  padding-right: ${space.md};
  margin-right: ${space.md};
  color: ${color.text.inverse};
  font-size: ${font.size.root};
  transition: opacity 0.4s ease;
  opacity: ${({ active, finished }) => !active && !finished && '0.5'};
  font-weight: ${({ active }) => (active ? font.weight.medium : font.weight.normal)};
  white-space: nowrap;

  &:hover {
    opacity: 1;
  }

  @media (min-width: ${breakpoint.hd}) {
    padding-right: ${space.xl};
    margin-right: ${space.xl};
  }
`

export const StepNumber = styled.span<StepStylesProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    margin-right: ${space.xs2};

    ${({ finished }) =>
      finished
        ? css`
            content: '';
          `
        : css`
            content: '.';
          `};

    @media (min-width: ${breakpoint.xl}) {
      display: none;
    }
  }

  @media (min-width: ${breakpoint.xl}) {
    min-width: 1.8rem;
    min-height: 1.8rem;
    margin-right: ${space.md};
    border-radius: ${borders.radius.circle};
    border-width: ${borders.sizes.md};
    border-style: solid;
    border-color: ${({ active, finished }) => (active || finished ? color.primary.pure : color.grayscale.white)};
    background-color: ${({ active, finished }) => (active && !finished ? color.primary.pure : 'transparent')};
    font-family: ${font.family.heading};
    font-size: ${font.size.small};
    font-weight: ${font.weight.bold};
    line-height: ${font.lineHeight.sm};
  }
`

export const Body = styled.div`
  padding: ${space.md} ${space.xs} ${space.xl3};
  min-height: ${space.xl4};

  @media (min-width: ${breakpoint.lg}) {
    padding: ${space.md} ${space.xl} ${space.xl3};
  }
`

export const WrapStartOnboarding = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StartOnboarding = styled(Button)`
  margin: 0 ${space.xs};

  @media (min-width: ${breakpoint.lg}) {
    margin: 0 ${space.lg};
  }
`

export const Block = styled.div`
  clear: both;
  padding-top: ${space.xl};
  padding-left: ${space.xs};
  padding-right: ${space.xs};

  &:not(:last-of-type) {
    padding-bottom: ${space.md};
    border-bottom: ${borders.sizes.md} dashed ${color.grayscale.light};
  }

  @media (min-width: ${breakpoint.lg}) {
    padding-top: ${space.md};
    padding-left: ${space.lg};
    padding-right: ${space.lg};
  }
`

export const Option = styled.div<{ direction?: string; isFull?: boolean }>`
  display: flex;
  flex-direction: ${(p) => p.direction ?? 'row'};
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${space.md};
  flex-wrap: wrap;

  .last_name,
  .first_name,
  .email {
    & .basic-input-element {
      background-color: #eaedf0;
    }
  }

  .no-finishing,
  .hot-foil,
  .blind-embossing,
  .digital-spot-varnish,
  .relief-embossing {
    display: none;
  }

  .finishing-text {
    color: #ffff;
    position: relative;
    left: -1.28rem;
    top: 0.73rem;
    width: 140px;
    height: 50px;
    padding-left: 1rem;
    padding-bottom: 1rem;
    background-color: #07263e;
    border-radius: 0 0 10px 10px;
  }

  @media (min-width: ${breakpoint.lg}) {
    flex-wrap: ${(p) => (p.isFull ? 'nowrap' : 'wrap')};

    .finishing-text {
      width: 156.5px;
    }
  }

  @media (min-width: ${breakpoint.xl2}) {
    .finishing-option {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: auto 1fr;
      row-gap: ${space.xl3};
    }

    .finishing-option > *:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
    }

    .finishing-option > * {
      grid-column: span 1;
      grid-row: 2;
    }

    .finishing-option > .checked-label {
      border: ${borders.sizes.md} solid ${color.primary.turquoiseBlue};
    }
  }
`

export const ArtworkOptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${space.md};
  width: 100%;
`

export const Hint = styled.sup`
  color: #808891;
  margin-left: ${space.xs2};
  cursor: help;
`

export const Divider = styled.span`
  min-width: ${space.xl};
  color: ${color.text.normal};
  font-size: ${font.size.small};
  font-weight: ${font.weight.medium};
  align-self: center;
`

export const OpenCalculator = styled.button<{ open: boolean }>`
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  border: 0;
  background-color: transparent;

  .icon {
    position: absolute;
    color: ${color.grayscale.white};
    top: 50%;
    left: 50%;

    ${(p) =>
      p.open
        ? css`
            transform: translate(-50%, -50%) rotate(180deg);
          `
        : css`
            transform: translate(-50%, -50%);
          `}
  }
`

export const PackageList = styled.div<{ name: string }>`
  display: flex;
  flex-direction: column;
  padding: ${space.sm} ${space.xl};
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${space.lg};
  width: 100%;
  border-radius: ${space.sm};

  background-color: ${({ name }) => (name === 'Smart' ? color.grayscale.lightBlue : color.grayscale.lightPurple)};

  @media (min-width: ${breakpoint.lg}) {
    grid-template-columns: repeat(3, 1fr);
    height: 100%;
    margin-top: 0;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media (min-width: ${breakpoint.hd}) {
    padding: ${space.sm};
  }
`

export const CleanCard = styled.div`
  width: 100%;
  height: 100%;
  padding: ${space.xl3} ${space.xl} ${space.xl};
  background-color: ${color.grayscale.light};
  border-radius: ${space.sm};

  & ul li span {
    font-size: ${font.size.small};
  }
  @media (min-width: ${breakpoint.lg}) {
    width: calc((100% / 2) + ${space.xl3} + ${space.xl3} + ${space.xl});
    margin-bottom: 0;
    padding: ${space.md} ${space.md} ${space.xl4};
  }
`

export const Scroll = styled(HorizontalScroll)`
  padding: ${space.xl} 0;
`

// Packages and pricing section v2 for configurator.
export const IndividualCardWrapper = styled.div<{
  direction?: string
  isFull?: boolean
}>`
  display: flex;
  flex-direction: ${(p) => p.direction ?? 'row'};
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${space.md};
  flex-wrap: ${(p) => (p.isFull ? 'nowrap' : 'wrap')};
`

export const IndividualCard = styled.div`
  display: flex;
  align-self: stretch;
  width: 9rem;
  height: auto;
  min-height: 1rem;
  border-radius: ${borders.radius.lg};
  cursor: pointer;
  transition: background-color 0.3s ease;

  @media (min-width: ${breakpoint.lg}) {
    width: 13rem;
  }
`

export const ListItemIcon = styled(Icon)`
  margin-top: 0.2rem;
  margin-right: ${space.xs};
`

export const OptionsWrapper = styled('div')`
  & .selected-radio-wrapper {
    gap: ${space.lg};
  }

  & .selected-radio-wrapper label {
    width: 100%;
    @media (min-width: ${breakpoint.lg}) {
      width: calc((100% / 2) - ${space.lg});
    }
  }
`
export const GeneralWrap = styled('div')<{ align?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${space.md};
  margin-top: ${space.sm};
  justify-content: space-between;

  @media (min-width: ${breakpoint.xs}) {
    flex-direction: row;
    justify-content: ${(stl) => (stl.align ? stl.align : 'space-between')};
  }

  @media (min-width: ${breakpoint.xl}) {
    gap: ${space.sm};
    justify-content: ${(stl) => (stl.align ? stl.align : 'flex-end')};
    margin-top: 0;
  }
`

export const ProgressBar = styled.progress`
  appearance: none;
  width: 100%;

  ::-webkit-progress-bar {
    background-color: transparent;
    height: 6px;
    border-radius: 0 3px 3px 0;
  }
  ::-webkit-progress-value {
    background-color: #0080ae;
    height: 6px;
    border-radius: 0 3px 3px 0;
  }
  ::moz-progress-bar {
    background-color: #0080ae;
    height: 6px;
    border-radius: 0 3px 3px 0;
  }
`
export const OptionWrapperBase = styled('div')`
  display: flex;
`

export const OptionAligner = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: ${space.sm};
  margin-bottom: ${space.md};
  @media (min-width: ${breakpoint.hd}) {
    flex-wrap: nowrap;
  }
`
export const VerticalRadioOption = styled('div')<{ dropdownState?: boolean; id?: any }>`
  & .v-option-wrapper {
    position: relative;
    justify-content: space-between;
  }

  & .v-select-card {
    @media (min-width: ${breakpoint.sm}) {
      width: calc((100% / 2) - ${space.lg});
    }
    @media (min-width: ${breakpoint.lg}) {
      width: calc((100% / 3) - ${space.lg});
    }

    width: 100%;
    position: relative;
    &:after {
      display: none;
    }
  }

  & .v-select-card.dropdown-active {
    &:after {
      content: '';
      width: 100%;
      height: 30%;
      background-color: transparent;
      z-index: 3;
      position: absolute;
      top: 35%;
      display: block;
    }
  }

  & label {
    min-height: 110px;
    width: 100%;
    position: relative;
    z-index: 2;
    flex-wrap: nowrap;
    flex-direction: initial;
    align-items: center;
  }

  & .checked-label {
    margin-bottom: ${(p) => (p.dropdownState ? '550px' : '0')};
    @media (min-width: ${breakpoint.md}) {
      margin-bottom: ${(p) => (p.dropdownState ? '300px' : '0')};
    }
  }

  ${(p) =>
    p.dropdownState &&
    `
    & .checked-label:before {
     content: '';
     position: absolute;
     top: 91%;
     left: -0.8%;
     width: 101.6%;
     height: 1.25rem;
     background: ${color.grayscale.lighter};
  }
  `}

  & label {
    align-self: flex-start;
  }

  & .left-fq,
  & .right-fq {
    display: none;
  }

  & .dropdown-active .left-fq,
  & .dropdown-active .right-fq {
    display: block;
    background-color: transparent;
  }
  & .right-fq {
    position: absolute;
    right: 0;
    top: 8px;
    width: 0;
    height: 16.5%;
    background: transparent;
    z-index: 4;
    border-left: 2px solid ${color.grayscale.darker};
    @media (min-width: ${breakpoint.md}) {
      height: 26.8%;
    }
  }

  & .left-fq {
    position: absolute;
    left: 0;
    top: 8px;
    width: 0;
    height: 16.5%;
    background: transparent;
    z-index: 4;
    border-right: 2px solid ${color.grayscale.darker};
    @media (min-width: ${breakpoint.md}) {
      height: 26.8%;
    }
  }

  & .v-select-card:nth-child(3n + 1) .left-fq {
    height: 30%;
  }
  & .v-select-card:nth-child(3n) .right-fq {
    height: 30%;
  }
`
export const OptionDropdown = styled('div')`
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  top: 100px;
  left: 0;
  background: ${color.grayscale.lighter};
  border: 2px solid ${color.grayscale.darker};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 2;
  overflow: hidden;
  @media (min-width: ${breakpoint.md}) {
    display: flex;
    z-index: 0;
  }
`

export const DropLink = styled('div')``

export const MaterialDetails = styled.div`
  margin: ${space.md};
  width: 100%;
  align-self: center;
`
export const MaterialImage = styled.div`
  margin: ${space.md};
  width: 80%;
  align-self: center;
`

export const MaterialDescription = styled.div`
  margin: ${space.xs};
  width: 100%;
`
export const BorderDivider = styled.div`
  margin-top: ${space.sm};
  &:not(:last-of-type) {
    padding-bottom: ${space.md};
    border-bottom: ${borders.sizes.md} dashed ${color.grayscale.light};
  }
`
export const Paragraph = styled.p`
  padding-left: ${space.xl};
  color: ${color.grayscale.dark};
  font-size: ${font.size.xsmall};
  line-height: ${font.lineHeight.md};
`
export const ImageAligner = styled.div`
  margin-top: ${space.md};
`
export const IconDetails = styled.div`
  color: ${color.grayscale.dark};
  font-size: ${font.size.xxsmall};
  min-inline-size: max-content;
`
export const CloseCard = styled.a`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`

export const IconAligner = styled.div`
  padding-right: ${space.xs2};
`

export const CBlock = styled.div`
  // display: flex;
  // flex-wrap: wrap;
  // max-width: calc(50% - 1rem);
`
export const CBlockInfo = styled.div`
  // width: 100%;
  // padding: 0.5rem 5rem 0 0;
  // display: flex;
  // align-items: baseline;
  // & .cblock-info-para-div {
  //   padding-left: 0.2rem;
  // }
`
