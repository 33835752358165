import { FC } from 'react'
import * as S from './styles'
import { IIcon } from './types'

const paths = {
  mastercard: 4,
  paypal: 7,
  spanish: 2,
  english: 5,
  productionexpress: 2,
  reload: 2,
  eco: 2,
  material: 5,
  'slide-left': 2,
  'slide-right': 2,
  'go-back': 3,
  'partial-varnish': 4,
  'small-roll': 4,
  'info-set': 4,
  'red-yellow-circle': 2,
  'grey-info': 4,
  'rectangular-slider-right': 2,
  'rectangular-slider-left': 2,
  'new-permanent': 4,
  safety: 2,
  'color-mode': 7,
  others: 8,
  'proof-no-cut': 4,
  'plus-icon-black': 2,
  'close-circle-s': 2,
  'red-info': 3,
  'help-question': 2
}

export const Icon: FC<IIcon> = ({ name, className, color, size = 's3', ...rest }) => {
  const cn = ['icon', `icon-${name}`, className].join(' ').trim()

  return (
    <S.Icon {...rest} className={cn} $size={size} $color={color}>
      {!!paths[name] && (
        <>
          {Array.from(Array(paths[name]).keys()).map((index) => (
            <span key={index} className={`path${index + 1}`} />
          ))}
        </>
      )}
    </S.Icon>
  )
}
