export const refineValueFromURL = (urlValue) => {
  const firstSplice = urlValue ? urlValue.replace('/my-account/label-library/', '') : ''
  const getCurrentTab = firstSplice.split('?')[0].split('/')[0] ?? ''
  const getCurrentPage = firstSplice.split('page=')[1] ?? 1

  if (!['myorder-files/', 'graphic-ticket-files/', 'design-service-files/'].includes(firstSplice)) {
    const getFolderName = firstSplice.split('?')[0].split('/')[1] ?? ''
    return { getCurrentTab, getCurrentPage, getFolderName }
  } else {
    const getFolderName = firstSplice.split('?')[0].split('/')[0] ?? ''
    return { getCurrentTab, getCurrentPage, getFolderName }
  }
}

// ****************************************
// ****************************************
// Job_id mostly for reorder functionality.
// ****************************************
// ****************************************

export const getJobIDFromURL = (urlValue) => {
  const jobId = urlValue ? urlValue.split('job_id=')[1] : undefined
  return { jobId }
}
