import { useQuery } from '@tanstack/react-query'
import { StorageKey } from '@/client/types'
import { getTransparencyBrands } from '@/infra/services'
import { getCSVInfo } from '@/infra/services/uploadFile'
import { useRouter } from 'next/router'
import { useContext, useState } from 'react'
import { CartContext } from '@/client/context/cart'
import { useMutatePrices } from '../prices'

export const useGetTransparencyBrands = (customerId: number | string, locale: string = 'en') =>
  useQuery([StorageKey.TRANSPARENCY_BRANDS, locale], {
    queryFn: () => getTransparencyBrands(customerId, locale),
    enabled: customerId !== 0 && customerId !== null && customerId !== undefined && customerId !== '0',
    refetchOnWindowFocus: false
  })

//csv upload result for transparency calculator
export const useGetCSVUploadInfo = (fileName, transparencyType, cID, selection, uploadSuccess, uploadedFilesRef) => {
  const { locale } = useRouter()
  const { mutate } = useMutatePrices(locale)
  const { updateSelection } = useContext(CartContext)
  // State to track the total sum of uploaded codes
  const [totalNum, setTotalNum] = useState(0)
  const { data, isLoading, isFetching, isRefetching, remove, isSuccess, status } = useQuery({
    queryKey: ['csv-result', fileName],
    queryFn: async () => {
      //Metadata for CSV processing
      const csvData = new FormData()
      csvData.append('lang', 'es')
      csvData.append('type', transparencyType)
      csvData.append('fileName', fileName)
      csvData.append('customerid', cID)
      const results = await getCSVInfo(csvData)

      // Determine new totalNum
      const newTotalNum =
        !selection.actual_quantity && !selection.upload_refresh ? results.data.num : totalNum + results.data.num

      setTotalNum(newTotalNum)

      // Ensure cart_file_item_id is an array and merge
      const updatedCartFileItemId = Array.isArray(selection.cart_file_item_id)
        ? [...selection.cart_file_item_id, results.data?.cartFileItemId]
        : [results.data?.cartFileItemId]

      const newSelection = {
        ...selection,
        upload_completed: newTotalNum === +selection.quantity ? true : false,
        upload_refresh: true,
        cart_file_item_id: updatedCartFileItemId,
        actual_quantity: selection.quantity
      }

      updateSelection(newSelection)
      mutate({ ...newSelection })
      uploadedFilesRef.current.push({
        name: fileName,
        numCodes: results.data?.num,
        price: 0,
        cartFileItem: results.data?.cartFileItemId
      })

      return results.data
    },
    enabled: uploadSuccess && !!fileName,
    keepPreviousData: false
  })
  return {
    isLoading,
    isFetching,
    isRefetching,
    remove,
    isSuccess,
    status,
    data: data,
    totalNum,
    updatedCartFileItemId: Array.isArray(selection.cart_file_item_id) ? [...selection.cart_file_item_id] : []
  }
}
