import { useQuery } from '@tanstack/react-query'
import { StorageKey } from '@/client/types'
import { getOrders, getArtworkServices, getDesignServices, getRecentOrders } from '@/infra/services'

const getTotalUploads = (data, type?: 'order' | 'services') => {
  let totalUploads: number = 0

  if (type === 'order') {
    totalUploads = data?.reduce((acc, curr) => {
      if (!curr?.jobs && curr.jobs.length === 0) return acc

      const jobsUploads = curr.jobs?.reduce((accumulator, current) => {
        if (current.isUploaded) {
          return accumulator + 1
        }
        return accumulator
      }, 0)

      return acc + jobsUploads
    }, 0)

    return totalUploads
  }

  totalUploads = data?.reduce((acc, curr) => {
    if (curr.isUploaded) {
      return acc + 1
    }
    return acc
  }, 0)

  return totalUploads
}

export const useGetOrders = (state) => {
  const query = useQuery([StorageKey.ORDERS, state], () => getOrders(state))
  const totalUploads = getTotalUploads(query?.data, 'order')

  return {
    ...query,
    totalUploads
  }
}

export const useGetArtworkServices = (state) => {
  const query = useQuery([StorageKey.ARTWORK_SERVICES, state], () => getArtworkServices(state))
  const totalUploads = getTotalUploads(query?.data)

  return {
    ...query,
    totalUploads
  }
}

export const useGetDesignServices = (state) => {
  const query = useQuery([StorageKey.DESIGN_SERVICES, state], () => getDesignServices(state))
  const totalUploads = getTotalUploads(query?.data)

  return {
    ...query,
    totalUploads
  }
}

// Get order details by job id and customer id
// Used to get previous orders and to do reorders.

export const useGetRecentOrders = (jobId) => {
  const query = useQuery([StorageKey.RECENT_ORDERS, jobId], () => getRecentOrders(jobId), {
    enabled: !!jobId,
    retry: 3,
    select: (data) => {
      const updatedData = { ...data, transparency_type: 0 }
      return updatedData
    }
  })

  return {
    ...query
  }
}
