import { ReactNode } from 'react'

// Constants for CMS
export const TABLE_CONTENT = 'table_content'
export const MAIN_TABLE = 'main_table'
export const COMMON_FIELDS = 'common_fields'
export const COMMON_FIELD_DATA = 'common_field_data'
export const COMMON_RICH_TEXT_EDITOR = 'common_rich_text_editors'
export const RICH_TEXT_EDITOR_FIELDS = 'rich_text_editor_fields'

export type MultiTableProps = {
  tableType: string
  page: number
  folderId?: string
  searchItem?: string
  folderName?: string
  content: any
}
