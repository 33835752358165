import { ICalculatorQuery } from '@/client/types/Calculator'

export const getCalculatorInfos = async (locale: string): Promise<ICalculatorQuery> => {
  const CMSApi = process.env.NEXT_PUBLIC_API_CMS
  let url = `${CMSApi}/pages/?slug=calculator-v2&acf_format=standard`
  if (locale === 'en') url += `&lang=${locale}`
  const res = await fetch(url)
  const [data] = await res.json()
  return data?.acf
}

export const getPackageInfos = async (locale: string): Promise<ICalculatorQuery> => {
  const CMSApi = process.env.NEXT_PUBLIC_API_CMS
  let url = `${CMSApi}/pages?slug=general-packages&acf_format=standard`
  if (locale === 'en') url += `&lang=${locale}`
  const res = await fetch(url)
  const [data] = await res.json()
  return data?.acf
}

export const getTransparencyCalculatorInfos = async (locale: string): Promise<ICalculatorQuery> => {
  const CMSApi = process.env.NEXT_PUBLIC_API_CMS
  let url = `${CMSApi}/pages/?slug=calculadora-transparencia&acf_format=standard`
  if (locale === 'en') url += `&lang=${locale}`
  const res = await fetch(url)
  const [data] = await res.json()
  return data?.acf
}
