import { useQuery, useMutation, useQueryClient, useIsMutating } from '@tanstack/react-query'
import { StorageKey } from '@/client/types'
import { getPrices, updatePrices } from '@/infra/services'

export const useGetPrices = (locale: string = 'es') =>
  useQuery([StorageKey.PRICES, locale], {
    queryFn: () => getPrices(locale),
    enabled: !!locale,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })

export const useMutatePrices = (locale: string = 'de', freezeCache = false) => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.MUTATE_PRICES])

  const { mutate } = useMutation({
    mutationKey: [StorageKey.MUTATE_PRICES],
    mutationFn: (data) => updatePrices(locale, data),
    onSuccess: (result) => {
      if (!freezeCache) {
        //When freeze is enabled, the response will not be cached, so the previous value will remain.
        queryClient.invalidateQueries({ queryKey: [StorageKey.PRICES, locale], refetchType: 'none' })
        queryClient.setQueryData([StorageKey.PRICES, locale], (oldData) =>
          oldData
            ? {
                ...oldData,
                calculatorFields: result.data.calculatorFields,
                optionFields: result.data.optionFields,
                price: result.data.price
              }
            : oldData
        )
      }
    }
  })

  return {
    mutate,
    isLoading: !!isLoading
  }
}
